import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import LoginButton from './LoginButton'
import MenuButton from './MenuButton'

const Header = () => {
  const { user, loading } = useSelector(state => state.auth)

  return (
    <header>
      <div className='header-container'>
        <MenuButton />
        <h3>Drink Water</h3>
        {loading
          ? <div className='loading'><FontAwesomeIcon icon={faSpinner} spin /></div>
          : (!user && <LoginButton />)}
      </div>
    </header>
  )
}

export default Header
