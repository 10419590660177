import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyANBgPmXGyCrs9wKGvMjk35QB5F2ZHT1-A',
  authDomain: 'drink-water-a7ee4.firebaseapp.com',
  projectId: 'drink-water-a7ee4',
  storageBucket: 'drink-water-a7ee4.appspot.com',
  messagingSenderId: '930415978340',
  appId: '1:930415978340:web:166b43cc725ddda558bc71',
  measurementId: 'G-K1QJFTSE55'
}

firebase.initializeApp(firebaseConfig)

if (window.location.hostname === 'localhost') {
  firebase.auth().useEmulator('http://localhost:9099/')
  firebase.firestore().useEmulator('localhost', 8080)
}

export { firebase }
