import PropTypes from 'prop-types'

const HistoricalCup = ({ percentage, currentDate }) => {
  return (
    <div className='cup-container'>
      <div className='cup historical'>
        <div className='remained' style={{ height: `${100 - percentage}%` }} />
        <div className='drank' style={{ height: `${percentage}%` }} />
      </div>
      <span className='label'>{currentDate.toFormat('MM/dd')}</span>
    </div>
  )
}

HistoricalCup.propTypes = {
  percentage: PropTypes.number.isRequired,
  currentDate: PropTypes.object.isRequired
}

export default HistoricalCup
