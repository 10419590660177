import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { firebase } from '../config'
import { setError, setLoading, setUser } from '../slices/authSlice'

const useAuth = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoading(true))

    const listener = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch(setUser({
          uid: user.uid
        }))
      } else {
        dispatch(setUser(null))
      }
      dispatch(setLoading(false))
    }, error => {
      dispatch(setError(error))
      dispatch(setLoading(false))
    })

    return () => listener()
  }, [dispatch])
}

export default useAuth
