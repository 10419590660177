import './App.css'
import { useSelector } from 'react-redux'

const LargeCup = () => {
  const { percentage, currentVolume } = useSelector(state => state.largeCup)
  const targetVolume = useSelector(state => state.preference.targetVolume)

  return (
    <div className='cup large'>
      <span className='text'>{currentVolume} / {targetVolume}<br />ml</span>
      <div className='remained' style={{ height: `${100 - percentage}%` }} />
      <div className='drank' style={{ height: `${percentage}%` }} />
    </div>
  )
}

export default LargeCup
