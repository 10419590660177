import './App.css'
import { useSelector } from 'react-redux'
import Header from './Header'
import CupContainer from './CupContainer'
import Menu from './Menu'
import TargetVolumeModal from './TargetVolumeModal'
import CupVolumeModal from './CupVolumeModal'
import EditCupModal from './EditCupModal'
import useRefreshPage from './hooks/useRefreshPage'
import useAuth from './hooks/useAuth'
import usePreferences from './hooks/usePreferences'

const App = () => {
  const { user, error } = useSelector(state => state.auth)

  useRefreshPage()
  useAuth()
  usePreferences()

  if (error) {
    return (
      <>
        <Header />
        <div className='container'>
          <span className='error'>Oops, something went wrong!</span>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      {user && (
        <>
          <Menu />
          <CupContainer />
          <TargetVolumeModal />
          <CupVolumeModal />
          <EditCupModal />
        </>
      )}
    </>
  )
}

export default App
