import { combineReducers } from 'redux'
import authReducer from './slices/authSlice'
import largeCupReducer from './slices/largeCupSlice'
import menuReducer from './slices/menuSlice'
import modalReducer from './slices/modalSlice'
import preferenceReducer from './slices/preferenceSlice'
import editCupReducer from './slices/editCupSlice'

export default combineReducers({
  auth: authReducer,
  largeCup: largeCupReducer,
  menu: menuReducer,
  modal: modalReducer,
  preference: preferenceReducer,
  editCup: editCupReducer
})
