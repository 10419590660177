import { createSlice } from '@reduxjs/toolkit'

const largeCupSlice = createSlice({
  name: 'largeCup',
  initialState: {
    percentage: 0,
    currentVolume: 0
  },
  reducers: {
    setPercentage: (state, { payload }) => {
      state.percentage = payload
    },
    setCurrentVolume: (state, { payload }) => {
      state.currentVolume = payload
    }
  }
})

export const { setPercentage, setCurrentVolume } = largeCupSlice.actions

export default largeCupSlice.reducer
