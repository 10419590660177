import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    error: null,
    loading: false
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    }
  }
})

export const { setUser, setError, setLoading } = authSlice.actions

export default authSlice.reducer
