import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { toggleEditCupModalVisible } from './slices/modalSlice'
import { setEditCupForm } from './slices/editCupSlice'

const SmallCup = ({ id, volume, createdAt }) => {
  const dispatch = useDispatch()

  const handleEditCup = () => {
    dispatch(setEditCupForm({ id, volume }))
    dispatch(toggleEditCupModalVisible())
  }

  return (
    <div className='cup-container'>
      <div className='cup small' onClick={handleEditCup} data-testid='small-cup'>
        <span className='text'>{volume} ml</span>
      </div>
      {createdAt && <span className='label'>{DateTime.fromSeconds(createdAt.seconds).toFormat('HH:mm')}</span>}
    </div>
  )
}

SmallCup.propTypes = {
  id: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  createdAt: PropTypes.shape({
    seconds: PropTypes.number
  })
}

export default SmallCup
