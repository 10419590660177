import { createSlice } from '@reduxjs/toolkit'

const preferenceSlice = createSlice({
  name: 'perference',
  initialState: {
    targetVolume: 2000,
    cupVolume: 250
  },
  reducers: {
    setTargetVolume: (state, { payload }) => {
      state.targetVolume = payload
    },
    setCupVolume: (state, { payload }) => {
      state.cupVolume = payload
    }
  }
})

export const { setTargetVolume, setCupVolume } = preferenceSlice.actions

export default preferenceSlice.reducer
