import { useSelector } from 'react-redux'
import { firebase } from './config'

const PlusSmallCup = () => {
  const user = useSelector(state => state.auth.user)
  const cupVolume = useSelector(state => state.preference.cupVolume)

  const handleAddCup = async () => {
    await firebase.firestore().collection(`/cups/${user.uid}/records`).add({
      volume: cupVolume,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })
  }

  return (
    <div className='cup-container'>
      <div className='cup small plus' onClick={handleAddCup} data-testid='small-cup'>
        <span className='text'>{cupVolume} ml</span>
      </div>
    </div>
  )
}

export default PlusSmallCup
