import { createSlice } from '@reduxjs/toolkit'

const editCupSlice = createSlice({
  name: 'editCup',
  initialState: {
    form: null
  },
  reducers: {
    setEditCupForm: (state, action) => {
      state.form = action.payload
    }
  }
})

export default editCupSlice.reducer
export const { setEditCupForm } = editCupSlice.actions
