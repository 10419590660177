import { useEffect } from 'react'
import { DateTime } from 'luxon'

const useRefreshPage = () => {
  useEffect(() => {
    const millisecondsToEndOfDay = DateTime.local().endOf('day').diffNow().milliseconds
    const timeout = setTimeout(() => {
      window.location.reload()
    }, millisecondsToEndOfDay)

    return () => clearTimeout(timeout)
  }, [])
}

export default useRefreshPage
