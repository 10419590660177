import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleCupVolumeModalVisible } from './slices/modalSlice'
import Modal from './Modal'
import { firebase } from './config'

const CupVolumeModal = () => {
  const dispatch = useDispatch()
  const [volume, setVolume] = useState(250)
  const [error, setError] = useState(null)
  const visible = useSelector(state => state.modal.cupVolumeModalVisible)
  const user = useSelector(state => state.auth.user)
  const cupVolume = useSelector(state => state.preference.cupVolume)

  const onSaveButtonClicked = async () => {
    try {
      await firebase.firestore().collection('preferences').doc(user.uid).set({
        cupVolume: parseInt(volume)
      }, { merge: true })
      dispatch(toggleCupVolumeModalVisible())
      setError(null)
    } catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    setVolume(cupVolume)
  }, [cupVolume])

  const isFormInvalid = () => {
    return parseInt(volume) <= 0
  }

  if (!visible) {
    return <></>
  }

  return (
    <Modal handleClickOutSideEvent={() => dispatch(toggleCupVolumeModalVisible())}>
      <>
        <span className='title'>Cup Volume (ml)</span>
        <input type='number' value={volume} onChange={e => setVolume(e.target.value)} autoFocus data-testid='volume-input' />
        {isFormInvalid() && <span className='error'>Drink more! At least 1 ml.</span>}
        {error && <span className='error'>Oops, something went wrong!</span>}
        <div className='buttons-container'>
          <button onClick={() => dispatch(toggleCupVolumeModalVisible())}>Cancel</button>
          <button onClick={onSaveButtonClicked} disabled={isFormInvalid()}>Save</button>
        </div>
      </>
    </Modal>
  )
}

export default CupVolumeModal
