import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { firebase } from '../config'
import { setCupVolume, setTargetVolume } from '../slices/preferenceSlice'

const usePreferences = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)

  useEffect(() => {
    if (user && user.uid) {
      const listener = firebase.firestore().collection('preferences').doc(user.uid).onSnapshot(snapshot => {
        const data = snapshot.data()
        if (data && data.targetVolume) {
          dispatch(setTargetVolume(data.targetVolume))
        }
        if (data && data.cupVolume) {
          dispatch(setCupVolume(data.cupVolume))
        }
      })

      return () => listener()
    }
  }, [user, dispatch])
}

export default usePreferences
