import { DateTime } from 'luxon'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { useSelector } from 'react-redux'
import HistoricalCup from './HistoricalCup'
import { firebase } from './config'

const HistoricalCupContainer = () => {
  const cupCount = 3
  const user = useSelector(state => state.auth.user)
  const targetVolume = useSelector(state => state.preference.targetVolume)

  const now = DateTime.local()
  const query = firebase.firestore().collection(`/cups/${user.uid}/records`)
    .where('createdAt', '>=', firebase.firestore.Timestamp.fromMillis(now.minus({ days: cupCount }).startOf('day').toMillis()))
    .where('createdAt', '<', firebase.firestore.Timestamp.fromMillis(now.startOf('day').toMillis()))
  const [values, loading, error] = useCollectionDataOnce(query)

  const cups = () => {
    const cups = []

    for (let i = cupCount; i >= 1; i--) {
      let percentage = 0
      const currentDate = now.minus({ days: i })

      const currentVolume = values
        .filter(value => DateTime.fromSeconds(value.createdAt.seconds).hasSame(currentDate, 'day'))
        .reduce((prev, cur) => prev + cur.volume, 0)
      percentage = Math.min(currentVolume / targetVolume * 100, 100)

      cups.push(<HistoricalCup key={i} percentage={percentage} currentDate={currentDate} />)
    }

    return cups
  }

  if (error || loading) {
    return (
      <></>
    )
  }

  return (
    <div className='historical-cups-container'>
      {cups()}
    </div>
  )
}

export default HistoricalCupContainer
