import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    targetVolumeModalVisible: false,
    cupVolumeModalVisible: false,
    editCupModalVisible: false
  },
  reducers: {
    toggleTargetVolumeModalVisible: state => {
      state.targetVolumeModalVisible = !state.targetVolumeModalVisible
    },
    toggleCupVolumeModalVisible: state => {
      state.cupVolumeModalVisible = !state.cupVolumeModalVisible
    },
    toggleEditCupModalVisible: state => {
      state.editCupModalVisible = !state.editCupModalVisible
    }
  }
})

export const { toggleTargetVolumeModalVisible, toggleCupVolumeModalVisible, toggleEditCupModalVisible } = modalSlice.actions

export default modalSlice.reducer
