import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMugHot, faTrophy, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { toggleMenuVisible } from './slices/menuSlice'
import { toggleTargetVolumeModalVisible, toggleCupVolumeModalVisible } from './slices/modalSlice'
import { firebase } from './config'
import HistoricalCupContainer from './HistoricalCupContainer'

const Menu = () => {
  const dispatch = useDispatch()
  const menuContainerRef = useRef(null)
  const menuVisible = useSelector(state => state.menu.menuVisible)

  const onEditTargetVolumeClicked = () => {
    dispatch(toggleMenuVisible())
    dispatch(toggleTargetVolumeModalVisible())
  }

  const onEditCupVolumeClicked = () => {
    dispatch(toggleMenuVisible())
    dispatch(toggleCupVolumeModalVisible())
  }

  const onLogOutClicked = () => {
    firebase.auth().signOut()
    dispatch(toggleMenuVisible())
  }

  const handleMouseDown = e => {
    if (menuVisible && menuContainerRef.current && !menuContainerRef.current.contains(e.target)) {
      dispatch(toggleMenuVisible())
    }
  }

  return (
    <div className={`menu ${menuVisible ? 'show' : ''}`} onMouseDown={handleMouseDown}>
      <div className='background background-first'>
        <div className='menu-container' ref={menuContainerRef}>
          <HistoricalCupContainer />
          <ul>
            <li onClick={onEditTargetVolumeClicked}><button><FontAwesomeIcon icon={faTrophy} className='icon' fixedWidth />Change Target Volume</button></li>
            <li onClick={onEditCupVolumeClicked}><button><FontAwesomeIcon icon={faMugHot} className='icon' fixedWidth />Change Cup Volume</button></li>
            <li onClick={onLogOutClicked}><button><FontAwesomeIcon icon={faSignOutAlt} className='icon' fixedWidth />Log Out</button></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Menu
