import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from './Modal'
import { toggleEditCupModalVisible } from './slices/modalSlice'
import { firebase } from './config'

const EditCupModal = () => {
  const dispatch = useDispatch()
  const visible = useSelector(state => state.modal.editCupModalVisible)
  const form = useSelector(state => state.editCup.form)
  const userId = useSelector(state => state.auth.user.uid)
  const [volume, setVolume] = useState(0)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (form) {
      setVolume(form.volume)
    }
  }, [form])

  const onDeleteButtonClicked = async () => {
    try {
      await firebase.firestore().collection(`/cups/${userId}/records`).doc(form.id).delete()
      setError(null)
      dispatch(toggleEditCupModalVisible())
    } catch (err) {
      setError(err)
    }
  }

  const onSaveButtonClicked = async () => {
    try {
      await firebase.firestore().collection(`/cups/${userId}/records`).doc(form.id).update({ volume: parseInt(volume) })
      setError(null)
      dispatch(toggleEditCupModalVisible())
    } catch (err) {
      setError(err)
    }
  }

  const isFormInvalid = () => {
    return parseInt(volume) <= 0
  }

  if (!visible || !form) {
    return <></>
  }

  return (
    <Modal handleClickOutSideEvent={() => dispatch(toggleEditCupModalVisible())}>
      <>
        <span className='title'>Edit Cup</span>
        <input type='number' value={volume} onChange={e => setVolume(e.target.value)} autoFocus data-testid='volume-input' />
        {isFormInvalid() && <span className='error'>Drink more! At least 1 ml.</span>}
        {error && <span className='error'>Oops, something went wrong!</span>}
        <div className='buttons-container'>
          <button className='danger' onClick={onDeleteButtonClicked}>Delete</button>
          <button onClick={() => dispatch(toggleEditCupModalVisible())}>Cancel</button>
          <button onClick={onSaveButtonClicked} disabled={isFormInvalid()}>Save</button>
        </div>
      </>
    </Modal>
  )
}

export default EditCupModal
