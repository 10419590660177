import { useRef } from 'react'
import PropTypes from 'prop-types'

const Modal = ({ children, handleClickOutSideEvent }) => {
  const modalContainerRef = useRef(null)

  const handleMouseDown = e => {
    if (modalContainerRef.current && !modalContainerRef.current.contains(e.target) && handleClickOutSideEvent) {
      handleClickOutSideEvent()
    }
  }

  return (
    <div className='modal' onMouseDown={handleMouseDown}>
      <div className='modal-container' ref={modalContainerRef}>
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  handleClickOutSideEvent: PropTypes.func
}

export default Modal
