import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons'
import { toggleMenuVisible } from './slices/menuSlice'

const MenuButton = () => {
  const dispatch = useDispatch()
  const menuVisible = useSelector(state => state.menu.menuVisible)

  const onMenuButtonClicked = () => {
    dispatch(toggleMenuVisible())
  }

  return (
    <button data-testid='menu-button' onClick={onMenuButtonClicked}><FontAwesomeIcon icon={menuVisible ? faTimes : faBars} fixedWidth /></button>
  )
}

export default MenuButton
