import './App.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firebase } from './config'
import LargeCup from './LargeCup'
import SmallCup from './SmallCup'
import PlusSmallCup from './PlusSmallCup'
import { setCurrentVolume, setPercentage } from './slices/largeCupSlice'

const CupContainer = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const { targetVolume } = useSelector(state => state.preference)
  const { menuVisible } = useSelector(state => state.menu)
  const { targetVolumeModalVisible, cupVolumeModalVisible } = useSelector(state => state.modal)

  const collection = firebase.firestore().collection(`/cups/${user.uid}/records`)
  const now = DateTime.local()
  const query = collection
    .where('createdAt', '>=', firebase.firestore.Timestamp.fromMillis(now.startOf('day').toMillis()))
    .where('createdAt', '<', firebase.firestore.Timestamp.fromMillis(now.endOf('day').toMillis()))
    .orderBy('createdAt', 'asc')
  const [values, loading, error] = useCollectionData(query, { idField: 'id' })

  useEffect(() => {
    if (!loading) {
      const currentVolume = values.reduce((prev, cur) => prev + cur.volume, 0)

      dispatch(setCurrentVolume(currentVolume))
      const percentage = Math.min(currentVolume / targetVolume, 1)
      dispatch(setPercentage(percentage * 100))
    }
  }, [loading, values, targetVolume, dispatch])

  if (loading) {
    return (
      <div className='container'>
        <div className='loading'>
          <div className='cup-container'>
            <div className='cup small'>
              <div className='remained' />
              <div className='drank' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='container'>
        <span className='error'>Oops, something went wrong!</span>
      </div>
    )
  }

  return (
    <div className={`container ${(menuVisible || targetVolumeModalVisible || cupVolumeModalVisible) ? 'blur' : ''}`}>
      <LargeCup />
      <div className='small-cups-container'>
        {values.map((value, index) => (
          <SmallCup key={index} id={value.id} volume={value.volume} createdAt={value.createdAt} />
        ))}

        <PlusSmallCup />
      </div>
    </div>
  )
}

export default CupContainer
