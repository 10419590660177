import { createSlice } from '@reduxjs/toolkit'

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuVisible: false
  },
  reducers: {
    toggleMenuVisible: state => {
      state.menuVisible = !state.menuVisible
    }
  }
})

export const { toggleMenuVisible } = menuSlice.actions

export default menuSlice.reducer
