import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleTargetVolumeModalVisible } from './slices/modalSlice'
import Modal from './Modal'
import { firebase } from './config'

const TargetVolumeModal = () => {
  const dispatch = useDispatch()
  const [volume, setVolume] = useState(2000)
  const [error, setError] = useState(null)
  const visible = useSelector(state => state.modal.targetVolumeModalVisible)
  const user = useSelector(state => state.auth.user)
  const targetVolume = useSelector(state => state.preference.targetVolume)

  const onSaveButtonClicked = async () => {
    try {
      await firebase.firestore().collection('preferences').doc(user.uid).set({
        targetVolume: parseInt(volume)
      }, { merge: true })
      dispatch(toggleTargetVolumeModalVisible())
      setError(null)
    } catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    setVolume(targetVolume)
  }, [targetVolume])

  const isFormInvalid = () => {
    return parseInt(volume) < 2000
  }

  if (!visible) {
    return <></>
  }

  return (
    <Modal handleClickOutSideEvent={() => dispatch(toggleTargetVolumeModalVisible())}>
      <>
        <span className='title'>Target Volume (ml)</span>
        <input type='number' value={volume} onChange={e => setVolume(e.target.value)} autoFocus data-testid='volume-input' />
        {isFormInvalid() && <span className='error'>Drink at least 2000 ml daily.</span>}
        {error && <span className='error'>Oops, something went wrong!</span>}
        <div className='buttons-container'>
          <button onClick={() => dispatch(toggleTargetVolumeModalVisible())}>Cancel</button>
          <button onClick={onSaveButtonClicked} disabled={isFormInvalid()}>Save</button>
        </div>
      </>
    </Modal>
  )
}

export default TargetVolumeModal
