import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { firebase } from './config'

const LoginButton = () => {
  const login = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().signInWithPopup(provider)
  }

  return (
    <button onClick={login}><span>Log In</span><FontAwesomeIcon icon={faSignInAlt} /></button>
  )
}

export default LoginButton
